<template>
  <Layout
    header-class="header-left-align"
    nav-alignment="left"
    :showTopHeader="false"
    :showThemeSwitcherButton="false"
    :footerStyle="2"
  >
    <!-- Start Slider Area -->
    <div
      class="slider-area slider-style-1 bg-transparent"
      style="
        height: calc(100vh - 80px);
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div class="butterfly-gl" ref="containerRef">
        <canvas
          ref="canvasRef"
          class="butterfly-canvas butterfly-canvas-social"
        ></canvas>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 d-none d-sm-block">
            <div class="text-left">
              <div
                class="title display-small-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="
                  color: rgb(0, 0, 0);
                  font-weight: 300;
                  letter-spacing: 1%;
                  line-height: 61.1px;
                  font-size: 46px;
                "
              >
                提升50倍制作效率
              </div>
              <div
                class="title display-small-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="
                  color: rgb(0, 0, 0);
                  font-weight: 900;
                  line-height: 162px;
                  font-size: 73.7px;
                "
              >
                面料企业的超级AI花型服务
              </div>
              <div
                class="title display-small-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="
                  color: rgb(0, 0, 0);
                  font-weight: 300;
                  font-size: 32.2px;
                  line-height: 65.2px;
                "
              >
                AI描稿 · AI仿稿 · AI花型开发 · AI效果图 · AI图像算法定制
              </div>
              <!-- <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default btn-icon" to="#">
                  开始体验
                </router-link>
              </div> -->
            </div>
          </div>
          <div class="d-block d-sm-none col-12">
            <div class="text-left">
              <div
                class="title display-small-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="
                  color: rgb(0, 0, 0);
                  font-weight: 300;
                  letter-spacing: 1%;
                  line-height: 40px;
                  font-size: 36px;
                "
              >
                提升50倍制作效率
              </div>
              <div
                class="title display-small-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="
                  color: rgb(0, 0, 0);
                  font-weight: 900;
                  line-height: 80px;
                  font-size: 58px;
                "
              >
                <div>面料企业的</div><div style="white-space: nowrap;">超级AI花型服务</div>
              </div>
              <div
                class="title display-small-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="
                  color: rgb(0, 0, 0);
                  font-weight: 300;
                  font-size: 30px;
                  line-height: 34px;
                "
              >
                <div>AI描稿 · AI仿稿</div>
                <div>AI花型开发 · AI效果图</div>
                <div>AI图像算法定制</div>
              </div>
              <!-- <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default btn-icon" to="#">
                  开始体验
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <div style="margin-top: 170px">
      <div class="wall">
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/1.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/2.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/3.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/4.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/5.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/6.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/7.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/8.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/17.png"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <div class="wall" data-direction="reverse" style="--wall-item-number: 9">
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/9.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/10.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/11.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/12.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/13.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/14.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/15.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/16.png"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="wall-item">
          <img
            src="../assets/images/home/scroll/18.png"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div>

    <div class="rn-service-area rn-section-gap" id="service">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              title="AI花型服务"
              description=""
              data-aos="fade-up"
              :sectionTitleStyle="2"
            />
          </div>
        </div>
        <div
          class="row align-items-center"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div
            class="col-lg-4 col-12 hover-shadow"
            style="cursor: pointer; border-radius: 11.5px"
            @click="goTo('/publicize')"
          >
            <div style="padding: 12px 31px; color: black">
              <div
                style="
                  height: 378px;
                  width: 100%;
                  overflow: hidden;
                  background: white;
                "
              >
                <div
                  style="transform: rotateZ(75deg) translateX(-50px)"
                  class="wall-two-div"
                >
                  <div class="wall-two">
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/1.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/2.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/3.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/4.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/5.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/6.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/7.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/8.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/9.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/1/5.png"
                      />
                    </div>
                  </div>
                  <div class="wall-two" data-direction="reverse">
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/1.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/2.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/3.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/4.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/5.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/6.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/7.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/8.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/9.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/2/10.png"
                      />
                    </div>
                  </div>
                  <div class="wall-two">
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/1.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/2.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/3.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/4.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/5.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/6.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/7.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/8.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/9.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/3/5.png"
                      />
                    </div>
                  </div>
                  <div class="wall-two" data-direction="reverse">
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/1.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/2.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/3.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/4.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/5.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/6.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/7.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/8.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/9.png"
                      />
                    </div>
                    <div class="wall-two-item">
                      <img
                        src="https://image.yuanqiai.xyz/homepage/images/home/wall/4/10.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="subTitleFont" style="font-weight: bold">
                AI花型开发
              </div>
              <div>
                <div class="thirdTitleFont">AI独家花型定向开发</div>
                <div class="thirdTitleFont">海量公版图库，每日上新200张</div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-12 hover-shadow"
            style="cursor: pointer; border-radius: 11.5px"
            @click="goTo('/description')"
          >
            <div style="padding: 12px 31px; color: black">
              <div>
                <div class="compare-container">
                  <div class="compare-left-bg"></div>
                  <div class="compare-right-bg"></div>
                </div>
              </div>
              <div class="subTitleFont" style="font-weight: bold">
                AI描稿·照片生成花型文件
              </div>
              <div>
                <div class="thirdTitleFont">
                  任意 照片/扫描件/截图，生成花型文件
                </div>
                <div class="thirdTitleFont">文件形式：高清循环分层文件</div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-12 hover-shadow"
            style="cursor: pointer; border-radius: 11.5px"
            @click="goTo('/imitation')"
          >
            <div style="padding: 12px 31px; color: black">
              <div>
                <div class="compare-container">
                  <div class="compare-left-bg compare-left-bg-two"></div>
                  <div class="compare-right-bg compare-right-bg-two"></div>
                </div>
              </div>
              <div class="subTitleFont" style="font-weight: bold">
                AI仿稿·花型版权规避
              </div>
              <div>
                <div class="thirdTitleFont">同风格花型生成</div>
                <div class="thirdTitleFont">花型元素定向替换</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap" id="service2">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 col-12" style="height: 100%">
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div class="firstTitleFont" style="margin-bottom: 20px">
                AI面料<br />
                效果图服务
              </div>
              <div>
                <div
                  style="
                    width: 106px;
                    height: 34.2px;
                    border-radius: 6px;
                    box-shadow: 0px 3.3px 5px rgba(0, 0, 0, 0.1);
                    font-weight: 350;
                    font-size: 17.6px;
                    line-height: 35.2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    cursor: pointer;
                  "
                  @click="changeSelectOne(0)"
                  :class="selectOne == 0 ? 'buttonActiveBg' : 'buttonBg'"
                >
                  <div :class="selectOne == 0 ? 'activeDot' : 'dot'"></div>
                  <div>服装面料</div>
                </div>
                <div
                  style="
                    width: 106px;
                    height: 34.2px;
                    border-radius: 6px;
                    box-shadow: 0px 3.3px 5px rgba(0, 0, 0, 0.1);
                    font-weight: 350;
                    font-size: 17.6px;
                    line-height: 35.2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  "
                  @click="changeSelectOne(1)"
                  :class="selectOne == 1 ? 'buttonActiveBg' : 'buttonBg'"
                >
                  <div :class="selectOne == 1 ? 'activeDot' : 'dot'"></div>
                  <div>家纺面料</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12" style="height: 684px">
            <video
              src="https://image.yuanqiai.xyz/homepage/videos/1.mp4"
              :controls="false"
              autoplay
              muted
              loop
              style="
                width: 100%;
                height: 684px;
                object-fit: contain;
                background: black;
              "
              v-if="selectOne == 0"
            />
            <video
              src="https://image.yuanqiai.xyz/homepage/videos/2.mp4"
              :controls="false"
              autoplay
              muted
              loop
              style="width: 100%; height: 684px; object-fit: cover"
              v-else
            />
          </div>
          <div
            class="col-lg-4 col-12"
            style="height: 684px"
            v-if="selectOne == 0"
          >
            <div class="row" style="height: calc(342px - 0.75rem)">
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/1_1.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/1_2.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
            </div>
            <div
              class="row"
              style="height: calc(342px - 0.75rem); margin-top: 1.5rem"
            >
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/1_3.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/1_4.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12" style="height: 684px" v-else>
            <div class="row" style="height: calc(342px - 0.75rem)">
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/2_1.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/2_2.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
            </div>
            <div
              class="row"
              style="height: calc(342px - 0.75rem); margin-top: 1.5rem"
            >
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/2_3.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
              <div class="col-6 video-container">
                <video
                  src="https://image.yuanqiai.xyz/homepage/videos/2_4.mp4"
                  :controls="false"
                  muted
                  loop
                  style="width: 100%; height: 100%; object-fit: cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rn-service-area rn-section-gap" id="tool">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="firstTitleFont">数码印花图案——设计师免费工具</div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <div class="row">
            <div class="col-lg-9 col-12">
              <div
                style="width: 100%; border-radius: 11.5px; margin-bottom: 20px"
              >
                <VueCompareImage
                  :leftImage="beforeImage"
                  :rightImage="afterImage"
                  leftLabel="Before"
                  rightLabel="After"
                  :handleSize="56"
                />
              </div>
            </div>
            <div
              class="col-lg-3 col-12"
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
              "
            >
              <div style="margin-bottom: 20px">
                <div
                  style="
                    width: 150px;
                    height: 34.2px;
                    border-radius: 6px;
                    box-shadow: 0px 3.3px 5px rgba(0, 0, 0, 0.1);
                    font-weight: 350;
                    font-size: 17.6px;
                    line-height: 35.2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    cursor: pointer;
                    transition: 0.3s;
                  "
                  :class="toolActive == 0 ? 'buttonActiveBg' : 'buttonBg'"
                  @click="changeToolActive(0)"
                >
                  <div :class="toolActive == 0 ? 'activeDot' : 'dot'"></div>
                  <div>普通高清放大</div>
                </div>
                <div
                  style="
                    width: 150px;
                    height: 34.2px;
                    border-radius: 6px;
                    box-shadow: 0px 3.3px 5px rgba(0, 0, 0, 0.1);
                    font-weight: 350;
                    font-size: 17.6px;
                    line-height: 35.2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-bottom: 10px;
                    transition: 0.3s;
                  "
                  :class="toolActive == 1 ? 'buttonActiveBg' : 'buttonBg'"
                  @click="changeToolActive(1)"
                >
                  <div :class="toolActive == 1 ? 'activeDot' : 'dot'"></div>
                  <div>自动接循环</div>
                </div>
                <div
                  style="
                    width: 150px;
                    height: 34.2px;
                    border-radius: 6px;
                    box-shadow: 0px 3.3px 5px rgba(0, 0, 0, 0.1);
                    font-weight: 350;
                    font-size: 17.6px;
                    line-height: 35.2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: 0.3s;
                  "
                  :class="toolActive == 2 ? 'buttonActiveBg' : 'buttonBg'"
                  @click="changeToolActive(2)"
                >
                  <div :class="toolActive == 2 ? 'activeDot' : 'dot'"></div>
                  <div>一键分图层</div>
                </div>
              </div>
              <div
                style="
                  width: 137.4px;
                  height: 53.7px;
                  border-radius: 6.1px;
                  background: rgba(0, 0, 0, 1);
                  position: relative;
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                "
              >
                <div style="margin-right: 20px" @click="jumpToTool">
                  立即体验
                </div>
                <div
                  style="
                    position: absolute;
                    top: 0;
                    right: -26.85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 53.7px;
                    height: 53.7px;
                    background: rgba(236, 247, 255, 1);
                    border-radius: 50%;
                    color: black;
                    font-size: 32px;
                  "
                >
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rn-service-area rn-section-gap" id="service3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="firstTitleFont">AI生图算法企业定制</div>
            <div class="subTitleFont">
              帮助纺织企业定制AI生图软件，培训企业内部设计师学会AI，完成AI化转型，降本增效
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-12">
              <div class="example-3d">
                <swiper class="swiper" :options="swiperOption">
                  <swiper-slide>
                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        padding: 19.4px 16px 15.8px 16px;
                      "
                    >
                      <div
                        style="
                          width: 319.6px;
                          height: 312.9px;
                          border-radius: 12.7px;
                        "
                      >
                        <img
                          src="https://image.yuanqiai.xyz/homepage/images/home/suanfa2.png"
                          style="width: 100%; height: 100%"
                        />
                      </div>
                      <div
                        style="
                          font-weight: 700;
                          font-size: 21.1px;
                          line-height: 40.6px;
                        "
                      >
                        定位花填色系统开发系统
                      </div>
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        "
                      >
                        <div>
                          <div
                            style="
                              font-weight: 700;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            5天生产1张图
                          </div>
                          <div
                            style="
                              font-weight: 350;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            Before
                          </div>
                        </div>
                        <div>
                          <div
                            style="
                              font-weight: 700;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            1天生产50张图
                          </div>
                          <div
                            style="
                              font-weight: 350;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            After
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        padding: 19.4px 16px 15.8px 16px;
                      "
                    >
                      <div
                        style="
                          width: 319.6px;
                          height: 312.9px;
                          border-radius: 12.7px;
                        "
                      >
                        <img
                          src="https://image.yuanqiai.xyz/homepage/images/home/suanfa1.png"
                          style="width: 100%; height: 100%"
                        />
                      </div>
                      <div
                        style="
                          font-weight: 700;
                          font-size: 21.1px;
                          line-height: 40.6px;
                        "
                      >
                        波斯地毯AI生图系统开发
                      </div>
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        "
                      >
                        <div>
                          <div
                            style="
                              font-weight: 700;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            5天生产1张图
                          </div>
                          <div
                            style="
                              font-weight: 350;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            Before
                          </div>
                        </div>
                        <div>
                          <div
                            style="
                              font-weight: 700;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            1天生产50张图
                          </div>
                          <div
                            style="
                              font-weight: 350;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            After
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        padding: 19.4px 16px 15.8px 16px;
                      "
                    >
                      <div
                        style="
                          width: 319.6px;
                          height: 312.9px;
                          border-radius: 12.7px;
                        "
                      >
                        <img
                          src="https://image.yuanqiai.xyz/homepage/images/home/suanfa3.png"
                          style="width: 100%; height: 100%"
                        />
                      </div>
                      <div
                        style="
                          font-weight: 700;
                          font-size: 21.1px;
                          line-height: 40.6px;
                        "
                      >
                        家纺效果图开发系统
                      </div>
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        "
                      >
                        <div>
                          <div
                            style="
                              font-weight: 700;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            5天生产1张图
                          </div>
                          <div
                            style="
                              font-weight: 350;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            Before
                          </div>
                        </div>
                        <div>
                          <div
                            style="
                              font-weight: 700;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            1天生产50张图
                          </div>
                          <div
                            style="
                              font-weight: 350;
                              font-size: 18.6px;
                              line-height: 40.6px;
                            "
                          >
                            After
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rn-section-gap" id="company">
      <div class="container">
        <div
          class="row align-items-center"
          style="margin-bottom: 120px"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div class="col-lg-4 col-12">
            <div class="firstTitleFont">服务企业</div>
            <div
              style="
                font-weight: 400;
                font-size: 111.7px;
                line-height: 148.3px;
                color: black;
                font-family: SeymourOne;
              "
            >
              150+
            </div>
            <div class="subTitleFont">
              元七AI服务过150余家企业<br />其中包含10余家行业头部企业
            </div>
          </div>
          <div class="col-lg-5 col-12 thirdTitleFont">
            <div class="row" style="margin-bottom: 50px">
              <div class="col-6">
                <img
                  src="https://image.yuanqiai.xyz/homepage/images/company/1.svg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="col-6">
                <img
                  src="https://image.yuanqiai.xyz/homepage/images/company/2.svg"
                  style="width: 100%; height: auto"
                />
              </div>
            </div>
            <div class="row" style="margin-bottom: 50px">
              <div class="col-6">
                <img
                  src="https://image.yuanqiai.xyz/homepage/images/company/10.svg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="col-6">
                <img
                  src="../assets/images/company/11.svg"
                  style="width: 100%; height: auto"
                />
              </div>
            </div>
            <div class="row" style="margin-bottom: 50px">
              <div class="col-6">
                <img
                  src="https://image.yuanqiai.xyz/homepage/images/company/3.svg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="col-6">
                <img
                  src="https://image.yuanqiai.xyz/homepage/images/company/4.svg"
                  style="width: 100%; height: auto"
                />
              </div>
            </div>
            <div class="row" style="margin-bottom: 14px">
              <div class="col-6" style="padding-left: 25px">桐乡纪乔</div>
              <div class="col-6">广州万紫千红</div>
            </div>
            <div class="row" style="margin-bottom: 14px">
              <div class="col-6" style="padding-left: 25px">海宁美特尔纺织</div>
              <div class="col-6">湖州高睿经编</div>
            </div>
            <div class="row" style="margin-bottom: 14px">
              <div class="col-6" style="padding-left: 25px">深圳丽宝纺织</div>
              <div class="col-6">泽曼数码</div>
            </div>
            <div class="row" style="margin-bottom: 14px">
              <div class="col-6" style="padding-left: 25px">宝伦纺织</div>
              <div class="col-6">华泽数码</div>
            </div>
            <div class="row" style="margin-bottom: 14px">
              <div class="col-6" style="padding-left: 25px">玮如纺织</div>
              <div class="col-6">赛迪纺织</div>
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <img
              src="../assets/images/home/company1.png"
              style="width: 100%; height: auto; margin-bottom: 25px"
            />
            <!-- <img
              src="../assets/images/home/company2.png"
              style="width: 100%; height: auto"
            /> -->
            <div style="margin-top: 25px; text-align: right">More+</div>
          </div>
        </div>
        <div
          class="row align-items-center"
          id="company2"
          data-aos="fade-up"
          data-aos-delay="150"
        >
          <div class="col-lg-6 col-12">
            <div class="firstTitleFont">投资机构</div>
            <div
              style="
                font-weight: 400;
                font-size: 111.7px;
                line-height: 148.3px;
                color: black;
                font-family: SeymourOne;
              "
            >
              1000w+
            </div>
            <div class="subTitleFont">
              元七AI累计获得<br />浅石创投、唯猎资本、七熹投资等<br />千万元的投资
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div>
              <img
                src="../assets/images/company/25.png"
                style="width: 100%; height: auto"
              />
            </div>
            <div style="margin: 16px 0px">
              <img
                src="../assets/images/company/26.png"
                style="width: 100%; height: auto"
              />
            </div>
            <div>
              <img
                src="../assets/images/company/27.png"
                style="width: 100%; height: auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rwt-team-area rn-section-gap" id="team">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="left"
              title="元七团队"
              description="一只年轻但身经百战的AI技术团队"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--12">
          <div
            class="col-lg-3 col-md-6 col-12"
            v-for="(teamMember, index) in teamData"
            :key="index"
          >
            <Team :team-member="teamMember" :team-style="2" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Separator from '../components/elements/separator/Separator'
import Icon from '../components/icon/Icon'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import ServiceFive from '../components/elements/service/ServiceFive'
import Team from '../components/elements/team/Team'
import BlogPost from '../components/blog/BlogPost'
import BlogPostMixin from '../mixins/BlogPostMixin'
import CallToAction from '../components/elements/callToAction/CallToAction'
import Testimonial from '../components/elements/testimonial/Testimonial'
import Accordion from '../components/elements/accordion/Accordion'
import AboutFour from '../components/elements/about/AboutFour'
import WebGLFluidEmiyaGm from 'webgl-fluid-emiyagm'
import VueSlickCarousel from 'vue-slick-carousel'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import VueCompareImage from 'vue-compare-image-emiyagm'

export default {
  name: 'Home',
  components: {
    Accordion,
    Testimonial,
    CallToAction,
    BlogPost,
    Team,
    ServiceFive,
    SectionTitle,
    Icon,
    Separator,
    Layout,
    AboutFour,
    VueSlickCarousel,
    Swiper,
    SwiperSlide,
    VueCompareImage,
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        initialSlide: 1,
        coverflowEffect: {
          rotate: 0,
          stretch: 50,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        },
        slideToClickedSlide: true,
      },
      teamData: [
        {
          image: 'avatar1.jpg',
          name: '徐京',
          designation: 'Founder',
          location: 'CO Miego, AD, USA',
          description: '北科毕业，曾担任盒子空间联合创始人， OKtechLab founder',
        },
        {
          image: 'avatar2.jpg',
          name: '严澄峰',
          designation: 'Co-founder',
          location: 'Bangladesh',
          description: '北大毕业，曾担任盒子空间创始人',
        },
        {
          image: 'avatar3.png',
          name: '凯西',
          designation: '销售合伙人',
          location: 'Poland',
          description: '北大毕业，曾担任盒子空间联合创始人',
        },
        {
          image: 'avatar4.jpg',
          name: '贾敏',
          designation: '技术合伙人',
          location: 'Poland',
          description:
            '曾担任美团数据专家，L9级别，曾带领美团自数据领域打败竞对手',
        },
        {
          image: 'avatar5.jpg',
          name: '张亮',
          designation: '算法Leader',
          location: 'Poland',
          description: '曾负责商汤科技图片生成模型训练板块',
        },
        {
          image: 'avatar6.jpg',
          name: 'Webber Wu',
          designation: '后端Leader',
          location: 'Poland',
          description: '北科毕业，曾担任盒子空间联合创始人，OKtechLab founder',
        },
        {
          image: 'avatar7.png',
          name: '李子慕',
          designation: '美术Leader',
          location: 'Poland',
          description: '清美本科毕业，清美硕士在读，曾独立创办设计工作室',
        },
      ],
      selectOne: 0,
      beforeImage:
        'https://image.yuanqiai.xyz/homepage/images/home/tool/tool1before.png',
      afterImage:
        'https://image.yuanqiai.xyz/homepage/images/home/tool/tool1after.png',
      toolActive: 0,
    }
  },
  methods: {
    changeToolActive(index) {
      this.toolActive = index
      switch (index) {
        case 0:
          this.beforeImage =
            'https://image.yuanqiai.xyz/homepage/images/home/tool/tool1before.png'
          this.afterImage =
            'https://image.yuanqiai.xyz/homepage/images/home/tool/tool1after.png'
          break
        case 1:
          this.beforeImage =
            'https://image.yuanqiai.xyz/homepage/images/home/tool/tool2before.png'
          this.afterImage =
            'https://image.yuanqiai.xyz/homepage/images/home/tool/tool2after.png'
          break
        case 2:
          this.beforeImage =
            'https://image.yuanqiai.xyz/homepage/images/home/tool/tool3before.png'
          this.afterImage =
            'https://image.yuanqiai.xyz/homepage/images/home/tool/tool3after.png'
          break
        default:
          break
      }
    },
    goTo(url) {
      this.$router.push(url)
    },
    changeSelectOne(number) {
      this.selectOne = number
    },
    jumpToTool() {
      window.open('https://free.yuanqiai.xyz/', '_blank')
    },
  },
  mounted() {
    this.canvas = this.$refs.canvasRef
    WebGLFluidEmiyaGm(this.canvas, {
      BACK_COLOR: { r: 255, g: 255, b: 255 },
      HSV_DATA: [-0.07, 0.63, 0.3, 0.8],
      RGB_DATA: 0.1,
    })
    console.log(document.querySelector('.video-container'))
    document.querySelectorAll('.video-container').forEach((video) => {
      video.addEventListener('mouseenter', function () {
        this.querySelector('video').play()
      })
      video.addEventListener('mouseleave', function () {
        this.querySelector('video').pause()
        this.querySelector('video').currentTime = 0
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.butterfly-gl {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .butterfly-canvas {
    position: absolute;
    top: 0;
    transition: opacity 2s ease-in-out;
    height: 100%;
    width: 100%;
  }
}

.time-bg {
  background-image: url('../assets/images/home/timeline-logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}

.slick-image {
  height: 684px;
  width: 100%;
  object-fit: contain;
}

.hover-shadow {
  transition: 0.3s;
  // box-shadow: 0px 0px 0px hsl(0deg 0% 0% / 0.5);
}

.hover-shadow:hover {
  box-shadow: 8px 16px 16px hsl(0deg 0% 0% / 0.25);
  transform: translateY(-8px);
}

.compare-container {
  position: relative;
  background: #f1f1f1;
  width: 100%;
  height: 378px;
}
.compare-left-bg,
.compare-right-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  transition: 0.25s ease;
}
.compare-left-bg {
  background-image: url(https://image.yuanqiai.xyz/homepage/images/description/before.jpeg);
  background-size: auto 100%;
  left: 0;
}
.compare-right-bg {
  background-image: url(https://image.yuanqiai.xyz/homepage/images/description/after.jpeg);
  background-position: right;
  background-size: auto 100%;
  right: 0;
}
.compare-right-bg:before {
  content: '';
  background-image: url(../assets/images/home/splite-icon.png);
  font-weight: bold;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 169px;
  left: 0;
  background-size: contain;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
}
.compare-right-bg:after {
  content: ' ';
  height: 378px;
  width: 1px;
  background-color: white;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
}
.compare-right-bg:after {
  right: 0;
}
.compare-left-bg:hover {
  width: 70%;
}
.compare-left-bg:hover + .compare-right-bg {
  width: 30%;
}
.compare-right-bg:hover {
  width: 70%;
}
.compare-right-bg:hover + .compare-left-bg {
  width: 30%;
}

.compare-left-bg-two {
  background-image: url(https://image.yuanqiai.xyz/homepage/images/imitation/before.jpg);
  background-size: auto 100%;
  left: 0;
}
.compare-right-bg-two {
  background-image: url(https://image.yuanqiai.xyz/homepage/images/imitation/after.png);
  background-position: right;
  background-size: auto 100%;
  right: 0;
}

.example-3d {
  width: 100%;
  height: 605px;
  padding-top: 50px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 351px;
    height: 485px;
    font-weight: bold;
    font-size: 32px;
    background: white;
    background-position: center;
    background-size: cover;
    color: black;
    box-shadow: 0px 3.4px 16.9px rgba(0, 0, 0, 0.2);
    border-radius: 12.7px;
  }
}

@keyframes scrolling {
  to {
    transform: translateX(calc(var(--wall-item-width) * -1));
  }
}

.wall {
  margin: 5px auto;
  height: var(--wall-item-height);
  width: 100vw;
  position: relative;
  mask-image: linear-gradient(
    90deg,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.wall .wall-item {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(var(--wall-item-width) * var(--wall-item-number)));
  height: var(--wall-item-height);
  width: var(--wall-item-width);
  animation: scrolling var(--duration) linear infinite;
  cursor: pointer;
}

.wall[data-direction='reverse'] .wall-item {
  animation-direction: reverse;
}

.wall .wall-item:nth-child(1) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 1) *
      -1
  );
}

.wall .wall-item:nth-child(2) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 2) *
      -1
  );
}

.wall .wall-item:nth-child(3) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 3) *
      -1
  );
}

.wall .wall-item:nth-child(4) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 4) *
      -1
  );
}

.wall .wall-item:nth-child(5) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 5) *
      -1
  );
}

.wall .wall-item:nth-child(6) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 6) *
      -1
  );
}

.wall .wall-item:nth-child(7) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 7) *
      -1
  );
}

.wall .wall-item:nth-child(8) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 8) *
      -1
  );
}

.wall .wall-item:nth-child(9) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 9) *
      -1
  );
}

.wall .wall-item:nth-child(10) {
  animation-delay: calc(
    (var(--duration) / var(--wall-item-number)) * (var(--wall-item-number) - 10) *
      -1
  );
}

.wall:has(.wall-item:hover) .wall-item {
  animation-play-state: paused;
}

.wall-two {
  margin: 5px auto;
  height: var(--wall-item-two-height);
  width: auto;
  position: relative;
}

.wall-two .wall-two-item img {
  object-fit: cover;
  width: var(--wall-item-two-width);
  height: var(--wall-item-two-height);
}

.wall-two .wall-two-item {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(
    calc(var(--wall-item-two-width) * var(--wall-item-two-number) - 200px)
  );
  height: var(--wall-item-two-height);
  width: var(--wall-item-two-width);
  animation: scrolling var(--duration-two) linear infinite;
  animation-play-state: paused;
  cursor: pointer;
}

.wall-two[data-direction='reverse'] .wall-two-item {
  animation-direction: reverse;
}

.wall-two .wall-two-item:nth-child(1) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 1) * -1
  );
}

.wall-two .wall-two-item:nth-child(2) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 2) * -1
  );
}

.wall-two .wall-two-item:nth-child(3) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 3) * -1
  );
}

.wall-two .wall-two-item:nth-child(4) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 4) * -1
  );
}

.wall-two .wall-two-item:nth-child(5) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 5) * -1
  );
}

.wall-two .wall-two-item:nth-child(6) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 6) * -1
  );
}

.wall-two .wall-two-item:nth-child(7) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 7) * -1
  );
}

.wall-two .wall-two-item:nth-child(8) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 8) * -1
  );
}

.wall-two .wall-two-item:nth-child(9) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 9) * -1
  );
}

.wall-two .wall-two-item:nth-child(10) {
  animation-delay: calc(
    (var(--duration-two) / var(--wall-item-two-number)) *
      (var(--wall-item-two-number) - 10) * -1
  );
}

.wall-two-div:hover .wall-two-item {
  animation-play-state: running;
}

.video-container {
  transition: transform 0.3s; /* 放大的过渡动画 */
  transform: scale(1); /* 初始缩放比例 */
}

.video-container:hover {
  transform: scale(1.2); /* 鼠标悬停时放大的缩放比例 */
  z-index: 1;
}
</style>
