import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Publicize from '../views/Publicize'
import Description from '../views/Description'
import Imitation from '../views/Imitation'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '元7AI',
    },
  },
  {
    path: '/publicize',
    name: 'Publicize',
    component: Publicize,
    meta: {
      title: '元7AI',
    },
  },
  {
    path: '/description',
    name: 'Description',
    component: Description,
    meta: {
      title: '元7AI',
    },
  },
  {
    path: '/imitation',
    name: 'Imitation',
    component: Imitation,
    meta: {
      title: '元7AI',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.hash) {
    if (to.name == from.name) {
      const element = document.querySelector(to.hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      document.title = to.meta.title
      next()
      window.scrollTo(0, 0)
      setTimeout(() => {
        const element = document.querySelector(to.hash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 1000)
    }
  } else {
    document.title = to.meta.title
    next()
    window.scrollTo(0, 0)
  }
})

export default router
