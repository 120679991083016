<template>
  <div class="about-area about-style-4" style="padding: 170px 0px 90px 0px">
    <div
      class="container"
      style="
        padding: 40px;
        background-color: rgb(247, 247, 247);
        border-radius: 12px;
      "
    >
      <div class="row row--40 align-items-center">
        <div class="col-lg-6 mt_md--40 mt_sm--40">
          <div class="content">
            <div class="inner">
              <div>
                <div
                  style="
                    font-size: 36px;
                    line-height: 48px;
                    font-weight: 600;
                    color: rgb(12, 12, 12);
                  "
                >
                  元七AI优势
                </div>
                <div
                  style="
                    font-size: 18px;
                    line-height: 32px;
                    color: rgb(64, 64, 64);
                  "
                >
                  清北算法团队自主研发
                </div>
              </div>
              <ul class="feature-list" style="margin-top: 60px">
                <li>
                  <div class="title-wrapper">
                    <h4 class="title">超快制作速度：</h4>
                    <p class="text">50张以内，6小时返图</p>
                  </div>
                </li>
                <li>
                  <div class="title-wrapper">
                    <h4 class="title">超高性价比：</h4>
                    <p class="text">生成式AI算法支持，单张成本降低50%</p>
                  </div>
                </li>
                <li>
                  <div class="title-wrapper">
                    <h4 class="title">保证图案质量：</h4>
                    <p class="text">
                      一个需求，多个选项，选中为止。<br />
                      所有图案格式均为高清回位分层文件，保证可喷印
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <Video :video-data="videoData" column="col-12" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Video from '../video/Video'
import AboutVideo from '../../../assets/videos/about.mp4'

export default {
  name: 'AboutFour',
  components: { Video, Icon },
  props: {
    image: {},
  },
  data() {
    return {
      videoData: [
        {
          thumb: this.image,
          src: AboutVideo,
          autoplay: true
        },
      ],
    }
  },
}
</script>
