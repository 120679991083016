<template>
  <div class="copyright-area copyright-style-one variation-two">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-8 col-md-7 col-sm-12 col-12"
          style="display: flex; align-items: center"
        >
          <Logo />
          <div style="margin: 0px 40px">
            <div
              style="
                font-size: 16px;
                line-height: 24px;
                font-weight: 450;
                color: rgb(12, 12, 12);
              "
            >
              北京未来盒影科技有限公司
            </div>
            <div
              style="
                font-size: 14px;
                line-height: 22px;
                font-weight: 305;
                color: rgb(64, 64, 64);
              "
            >
              北京市朝阳区酒仙桥恒通商务园B座2层
            </div>
          </div>
          <div>
            <div
              style="
                font-size: 14px;
                font-weight: 450;
                color: rgb(12, 12, 12);
              "
            >
              杭州元柒科技有限公司
            </div>
            <div
              style="
                font-size: 12px;
                font-weight: 305;
                color: rgb(64, 64, 64);
              "
            >
              浙江省杭州市萧山区盈丰路望京B座8层
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
          <div style="display: flex;align-items: center;justify-content: flex-end; text-align: center;font-size: 14px;line-height: 22px;font-weight: 305;">
            <div style="margin: 0px 36px;">
                <div><img src="../../../assets/images/home/yuanqiwxcode.png" style="width: 64px;height: 64px;" /></div>
                <div style="margin-top: 6px;">小程序</div>
            </div>
            <div>
                <div><img src="../../../assets/images/home/qrcode.png" style="width: 64px;height: 64px;" /></div>
                <div style="margin-top: 6px;">企业微信</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Logo from '../../elements/logo/Logo'

export default {
  name: 'CopyrightTwo',
  components: { Icon, Logo },
}
</script>
