<template>
  <Layout
    header-class="header-left-align"
    nav-alignment="left"
    :showTopHeader="false"
    :showThemeSwitcherButton="false"
    :footerStyle="2"
  >
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div
          style="
            margin-bottom: 20px;
            font-size: 20px;
            display: flex;
            align-items: center;
          "
        >
          <div @click="$router.back()" style="cursor: pointer">
            <i class="el-icon-back"></i>
            返回
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
              style="
                font-weight: 300;
                font-size: 64px;
                line-height: 68px;
                color: black;
                margin-bottom: 47px;
              "
            >
              AI仿稿 · 花型版权规避
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div style="font-weight: 350; font-size: 24px; line-height: 46.7px">
              同风格花型生成
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-9" style="height: 600px">
            <div
              style="height: 600px; border-radius: 10px"
              class="chatDiv"
              data-aos="fade-up"
              data-aos-delay="150"
              v-if="showChat"
            >
              <div
                v-for="(message, index) in activeIndex == 0
                  ? messages
                  : activeIndex == 1
                  ? messagesTwo
                  : activeIndex == 2
                  ? messagesThree
                  : ''"
                :key="index"
                :class="message.sender == 'me' ? 'message-me' : 'message-other'"
                data-aos="fade-up"
                :data-aos-delay="1000 * (index + 1)"
              >
                <div class="message-avatar">
                  <img
                    src="../assets/images/publicize/avatar-other.png"
                    v-if="message.sender == 'other'"
                    style="width: 100%; height: 100%; border-radius: 50%"
                  />
                  <img
                    src="../assets/images/home/avatar2.png"
                    v-else
                    style="width: 100%; height: 100%; border-radius: 50%"
                  />
                </div>
                <div class="message-content">
                  <div v-if="message.type != 'image'">
                    {{ message.content }}
                  </div>
                  <div
                    v-if="message.images"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <div v-for="(image, index) in message.images" :key="index" :style="index % 2 == 0 ? 'margin-right: 5px' : 'margin-left: 5px'">
                      <img
                        :src="image.url"
                        style="width: 100%; height: auto"
                        class="content-img"
                      />
                      <div>
                        {{ image.text || '' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div
              :class="activeIndex == 0 ? 'active normal' : 'normal'"
              @click="changeActive(0)"
            >
              <div class="point"></div>
              <div>矢量</div>
            </div>
            <div
              :class="activeIndex == 1 ? 'active normal' : 'normal'"
              @click="changeActive(1)"
            >
              <div class="point"></div>
              <div>质感</div>
            </div>
            <div
              :class="activeIndex == 2 ? 'active normal' : 'normal'"
              @click="changeActive(2)"
            >
              <div class="point"></div>
              <div>热带风</div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 146px; margin-bottom: 35.5px">
          <div class="col-lg-12">
            <div style="font-weight: 350; font-size: 24px; line-height: 46.7px">
              花型元素定向替换
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12" style="margin-bottom: 20px;">
            <VueCompareImage
              :leftImage="beforeImage"
              :rightImage="afterImage"
              leftLabel="Before"
              rightLabel="After"
              :handleSize="56"
            />
          </div>
          <div class="col-lg-6 col-12">
            <VueCompareImage
              :leftImage="beforeImage2"
              :rightImage="afterImage2"
              leftLabel="Before"
              rightLabel="After"
              :handleSize="56"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import VueCompareImage from 'vue-compare-image-emiyagm'

export default {
  name: 'Imitation',
  components: {
    Layout,
    SectionTitle,
    VueCompareImage,
  },
  data() {
    return {
      beforeImage: 'https://image.yuanqiai.xyz/homepage/images/imitation/before.jpg',
      afterImage: 'https://image.yuanqiai.xyz/homepage/images/imitation/after.png',
      beforeImage2: 'https://image.yuanqiai.xyz/homepage/images/imitation/before2.png',
      afterImage2: 'https://image.yuanqiai.xyz/homepage/images/imitation/after2.png',
      activeIndex: 0,
      messages: [
        {
          sender: 'me',
          content: '参考这张图的抽象几何风格，换成清新的绿色适合衬衫裙的花型。',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/chat-1.png',
              text: '',
            },
          ],
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/chat-2.png',
              text: '区别度30%',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/chat-3.png',
              text: '区别度50%',
            },
          ],
        },
      ],
      messagesTwo: [
        {
          sender: 'me',
          content: '参考这张图的质感，随机生成几种花型。',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/zg/origin.png',
              text: '',
            },
          ],
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/zg/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/zg/2.png',
              text: '',
            },
          ],
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/zg/3.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/4.png',
              text: '',
            },
          ],
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/5.png',
              text: '',
            },
          ],
        },
      ],
      messagesThree: [
        {
          sender: 'me',
          content: '参考这张图，生成一些热带风花型',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/rdf/origin.png',
              text: '',
            },
          ],
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/rdf/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/rdf/2.png',
              text: '',
            },
          ],
        },
        {
          sender: 'me',
          content: '参考这张图东南亚的风格，再生成一些热带风花型',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/dny/origin.png',
              text: '',
            },
          ],
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/dny/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/imitation/dny/2.png',
              text: '',
            },
          ],
        },
      ],
      showChat: true,
    }
  },
  methods: {
    changeActive(index) {
      this.activeIndex = index
      this.showChat = false
      setTimeout(() => {
        this.showChat = true
      }, 300)
    },
  },
}
</script>

<style scoped>
.mask {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}

.normal {
  display: flex;
  font-weight: 350;
  font-size: 20px;
  line-height: 41px;
  align-items: center;
  margin-bottom: 36.6px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.active {
  color: rgba(0, 0, 0, 1);
}

.active > .point {
  background: rgba(0, 0, 0, 1);
}

.point {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  margin-right: 11px;
  margin-left: 64.6px;
}

.chatDiv {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 240, 240, 1) 99.57%
  );
  padding: 20px;
  overflow: auto;
}

.message-me {
  display: flex;
  margin-bottom: 20px;
}

.message-other {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

.message-avatar {
  width: 45px;
  height: 45px;
  flex: 0 0 45px;
}

.message-content {
  padding: 10px 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2.7px 6.7px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  flex-direction: column;
}

.content-img {
  cursor: pointer;
  transition: all 0.6s;
  object-fit: cover;
  max-height: 200px;
}

.content-img:hover {
  transform: scale(1.2);
}
</style>
