<template>
  <Layout
    header-class="header-left-align"
    nav-alignment="left"
    :showTopHeader="false"
    :showThemeSwitcherButton="false"
    :footerStyle="2"
  >
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div
          style="
            margin-bottom: 20px;
            font-size: 20px;
            display: flex;
            align-items: center;
          "
        >
          <div @click="$router.back()" style="cursor: pointer">
            <i class="el-icon-back"></i>
            返回
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
              style="
                font-weight: 300;
                font-size: 64px;
                line-height: 68px;
                margin-bottom: 45px;
                color: #000;
              "
            >
              AI描稿 · 照片生成花型文件
            </div>
            <div
              style="
                font-weight: 350;
                font-size: 24.1px;
                line-height: 46.8px;
                margin-bottom: 12.9px;
                color: #000;
              "
            >
              任意 照片/扫描件/截图，生成花型文件
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-12">
            <VueCompareImage
              :leftImage="beforeImage"
              :rightImage="afterImage"
              leftLabel="Before"
              rightLabel="After"
              :handleSize="56"
            />
          </div>
          <div class="col-lg-3 col-12">
            <div
              :class="activeIndex == 0 ? 'active normal' : 'normal'"
              @click="changeActive(0)"
            >
              <div class="point"></div>
              <div>场景照片生成文件</div>
            </div>
            <div
              :class="activeIndex == 1 ? 'active normal' : 'normal'"
              @click="changeActive(1)"
            >
              <div class="point"></div>
              <div>扫描件生成文件</div>
            </div>
            <div
              :class="activeIndex == 2 ? 'active normal' : 'normal'"
              @click="changeActive(2)"
            >
              <div class="point"></div>
              <div>面料照片生成文件</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
              style="
                font-weight: 350;
                font-size: 24.1px;
                line-height: 46.8px;
                margin-bottom: 12.9px;
                color: #000;
                margin-top: 173.8px;
                margin-bottom: 49px;
              "
            >
              文件形式：高清、循环、分层文件，可直接对接机器喷印
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div style="width: 100%; max-width: 380px; margin: 0 auto">
              <img
                src="https://image.yuanqiai.xyz/homepage/images/description/description1.png"
                style="width: 100%; height: 380px"
              />
            </div>
            <div
              style="
                font-weight: 300;
                font-size: 18.1px;
                line-height: 46.8px;
                color: black;
                margin-top: 16px;
                text-align: center;
              "
            >
              无缝四方连续，自然拼接过渡
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div style="width: 100%; max-width: 380px; margin: 0 auto">
              <img
                src="https://image.yuanqiai.xyz/homepage/images/description/description2.png"
                style="width: 100%; height: 380px"
              />
            </div>
            <div
              style="
                font-weight: 300;
                font-size: 18.1px;
                line-height: 46.8px;
                color: black;
                margin-top: 16px;
                text-align: center;
              "
            >
              高清图案：300dp，保证清晰印刷
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div style="width: 100%; max-width: 380px; margin: 0 auto">
              <img
                src="https://image.yuanqiai.xyz/homepage/images/description/description3.png"
                style="width: 100%; height: 380px"
              />
            </div>
            <div
              style="
                font-weight: 300;
                font-size: 18.1px;
                line-height: 46.8px;
                color: black;
                margin-top: 16px;
                text-align: center;
              "
            >
              分层图案：方便调色
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import VueCompareImage from 'vue-compare-image-emiyagm'

export default {
  name: 'Description',
  components: {
    Layout,
    SectionTitle,
    VueCompareImage,
  },
  data() {
    return {
      beforeImage: 'https://image.yuanqiai.xyz/homepage/images/description/before2.png',
      afterImage: 'https://image.yuanqiai.xyz/homepage/images/description/after2.png',
      activeIndex: 0,
    }
  },
  methods: {
    changeActive(index) {
      this.activeIndex = index
      switch (index) {
        case 0:
          this.beforeImage = 'https://image.yuanqiai.xyz/homepage/images/description/before2.png'
          this.afterImage = 'https://image.yuanqiai.xyz/homepage/images/description/after2.png'
          break
        case 1:
          this.beforeImage = 'https://image.yuanqiai.xyz/homepage/images/description/before.jpeg'
          this.afterImage = 'https://image.yuanqiai.xyz/homepage/images/description/after.jpeg'
          break
        case 2:
          this.beforeImage = 'https://image.yuanqiai.xyz/homepage/images/description/before3.png'
          this.afterImage = 'https://image.yuanqiai.xyz/homepage/images/description/after3.png'
          break
        default:
          break
      }
    },
  },
}
</script>

<style scoped>
.mask {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}

.normal {
  display: flex;
  font-weight: 350;
  font-size: 20px;
  line-height: 41px;
  align-items: center;
  margin-bottom: 36.6px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.active {
  color: rgba(0, 0, 0, 1);
}

.active > .point {
  background: rgba(0, 0, 0, 1);
}

.point {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  margin-right: 11px;
  margin-left: 64.6px;
  transition: 0.3s;
}
</style>
