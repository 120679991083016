<template>
  <Layout
    header-class="header-left-align"
    nav-alignment="left"
    :showTopHeader="false"
    :showThemeSwitcherButton="false"
    :footerStyle="2"
  >
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div
          style="
            margin-bottom: 20px;
            font-size: 20px;
            display: flex;
            align-items: center;
          "
        >
          <div @click="$router.back()" style="cursor: pointer">
            <i class="el-icon-back"></i>
            返回
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
              style="
                font-weight: 300;
                font-size: 64px;
                line-height: 68px;
                color: black;
                margin-bottom: 47px;
              "
            >
              AI花型开发——独家系列花型开发
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-3 col-12"
            style="
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
            "
          >
            <div
              :class="activeIndex == 0 ? 'active normal' : 'normal'"
              @click="changeActive(0)"
            >
              <div class="point"></div>
              <div>卡通类童装</div>
            </div>
            <div
              :class="activeIndex == 1 ? 'active normal' : 'normal'"
              @click="changeActive(1)"
            >
              <div class="point"></div>
              <div>晕染类服饰花型</div>
            </div>
            <div
              :class="activeIndex == 2 ? 'active normal' : 'normal'"
              @click="changeActive(2)"
            >
              <div class="point"></div>
              <div>儿童枕头</div>
            </div>
            <div
              :class="activeIndex == 3 ? 'active normal' : 'normal'"
              @click="changeActive(3)"
            >
              <div class="point"></div>
              <div>做旧地毯</div>
            </div>
          </div>
          <div class="col-lg-9 col-12" style="height: 600px">
            <div
              style="height: 600px; border-radius: 10px"
              class="chatDiv"
              data-aos="fade-up"
              data-aos-delay="150"
              v-if="showChat"
            >
              <div
                v-for="(message, index) in activeIndex == 0
                  ? messages
                  : activeIndex == 1
                  ? messagesTwo
                  : activeIndex == 2
                  ? messagesThree
                  : activeIndex == 3
                  ? messagesFour
                  : ''"
                :key="index"
                :class="message.sender == 'me' ? 'message-me' : 'message-other'"
                data-aos="fade-up"
                :data-aos-delay="1000 * (index + 1)"
              >
                <div class="message-avatar">
                  <img
                    src="../assets/images/publicize/avatar-other.png"
                    v-if="message.sender == 'other'"
                    style="width: 100%; height: 100%; border-radius: 50%"
                  />
                  <img
                    src="../assets/images/home/avatar2.png"
                    v-else
                    style="width: 100%; height: 100%; border-radius: 50%"
                  />
                </div>
                <div class="message-content">
                  <template v-if="message.type != 'image'">
                    {{ message.content }}
                  </template>
                  <template v-else>
                    <div class="row">
                      <div class="col-6">
                        <img
                          :src="message.images[0].url"
                          style="width: 100%; height: 100%;object-fit: cover;"
                          class="content-img"
                        />
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-6">
                            <img
                              :src="message.images[1].url"
                              style="width: 100%;object-fit: cover;"
                              class="content-img"
                            />
                          </div>
                          <div class="col-6">
                            <img
                              :src="message.images[2].url"
                              style="width: 100%;object-fit: cover;"
                              class="content-img"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <img
                              :src="message.images[3].url"
                              style="width: 100%;object-fit: cover;"
                              class="content-img"
                            />
                          </div>
                          <div class="col-6">
                            <img
                              :src="message.images[4].url"
                              style="width: 100%;object-fit: cover;"
                              class="content-img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!-- <img
              src="../assets/images/publicize/top1.png"
              style="width: 100%; height: auto"
            /> -->
          </div>
        </div>
      </div>
    </div>

    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              style="
                font-weight: 300;
                font-size: 64.1px;
                line-height: 46.8px;
                color: black;
                margin-bottom: 56.3px;
              "
            >
              海量公版图库，每日上新200张
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-12">
            <Video :video-data="videoData" column="col-12" />
          </div>
          <div class="col-lg-3 col-12" style="position: relative">
            <img
              src="https://image.yuanqiai.xyz/homepage/images/publicize/mobile.png"
              style="width: 100%; height: auto"
            />
            <div
              style="
                padding: 23.6px 22.3px 15.6px 22.8px;
                position: absolute;
                bottom: 105px;
                right: -78.8px;
                background: white;
                box-shadow: 0px 3.1px 3.1px rgba(0, 0, 0, 0.25);
                border-radius: 11.3px;
              "
            >
              <img
                src="../assets/images/publicize/homeWxCode.png"
                style="width: 139px; height: 139px"
              />
              <div
                style="
                  font-weight: 400;
                  font-size: 13.9px;
                  line-height: 27px;
                  margin-top: 6.3px;
                "
              >
                手机微信扫码，查看图库
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Video from '../components/elements/video/Video'
import videoBg from '../assets/images/home/video/videoBg.jpg'

export default {
  name: 'Publicize',
  components: {
    Layout,
    SectionTitle,
    Video,
  },
  data() {
    return {
      activeIndex: 0,
      videoData: [
        {
          thumb: videoBg,
          src: 'https://image.yuanqiai.xyz/homepage/videos/about.mp4',
          autoplay: true,
        },
      ],
      messages: [
        {
          sender: 'me',
          content: '帮我开发一套卡通系列花型，做童装用',
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/2.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/3.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/4.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/5.png',
              text: '',
            },
          ],
        },
      ],
      messagesTwo: [
        {
          sender: 'me',
          content: '帮我开发一套晕染类服饰花型，做衣服用',
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/yr/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/yr/2.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/yr/3.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/4.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/kt/5.png',
              text: '',
            },
          ],
        },
      ],
      messagesThree: [
        {
          sender: 'me',
          content: '帮我开发一套可用于做儿童枕头的花型',
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/zt/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/zt/2.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/zt/3.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/zt/4.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/zt/5.png',
              text: '',
            },
          ],
        },
      ],
      messagesFour: [
        {
          sender: 'me',
          content: '帮我开发一套用于做旧地毯的花型',
        },
        {
          sender: 'other',
          content: '收到',
        },
        {
          sender: 'other',
          type: 'image',
          images: [
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/dt/1.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/dt/2.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/dt/3.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/dt/4.png',
              text: '',
            },
            {
              url: 'https://image.yuanqiai.xyz/homepage/images/publicize/dt/5.png',
              text: '',
            },
          ],
        },
      ],
      showChat: true,
    }
  },
  methods: {
    changeActive(index) {
      this.activeIndex = index
      this.showChat = false
      setTimeout(() => {
        this.showChat = true
      }, 300)
    },
  },
}
</script>

<style scoped>
.mask {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}

.point {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  margin-right: 11px;
}

.normal {
  display: flex;
  font-weight: 350;
  font-size: 20px;
  line-height: 41px;
  align-items: center;
  margin-bottom: 36.6px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.active {
  color: rgba(0, 0, 0, 1);
}

.active > .point {
  background: rgba(0, 0, 0, 1);
}

.chatDiv {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 240, 240, 1) 99.57%
  );
  padding: 20px;
  overflow: auto;
}

.message-me {
  display: flex;
  margin-bottom: 20px;
}

.message-other {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

.message-avatar {
  width: 45px;
  height: 45px;
  flex: 0 0 45px;
}

.message-content {
  padding: 10px 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2.7px 6.7px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.content-img {
  cursor: pointer;
  transition: all 0.6s;
}

.content-img:hover {
  transform: scale(1.2);
}
</style>
