<template>
  <ul class="mainmenu">
    <li class="has-droupdown has-menu-child-item">
      <a href="#" style="color: rgb(64, 64, 64)">
        产品功能
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <ul class="submenu">
        <li>
          <router-link to="/#service">AI花型服务</router-link>
        </li>
        <li>
          <router-link to="/#service2">AI面料效果图服务</router-link>
        </li>
        <li>
          <router-link to="/#tool"
            >设计师免费AI工具</router-link
          >
        </li>
        <li @click="dialogVisible = true">
          <router-link to="">海量公版图库</router-link>
        </li>
        <li><router-link to="/#service3">AI图像算法定制</router-link></li>
      </ul>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="#" style="color: rgb(64, 64, 64)">
        公司
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <ul class="submenu">
        <li><router-link to="/#team">关于我们-清北团队</router-link></li>
        <li><router-link to="/#company2">投资方</router-link></li>
        <li><router-link to="/#company">合作伙伴</router-link></li>
      </ul>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="#" style="color: rgb(64, 64, 64)">
        联系我们
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <div class="submenu" style="min-width: 304px;">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            font-size: 14px;
            line-height: 22px;
            font-weight: 305;
            padding: 0px 20px;
          "
        >
          <div style="margin-right: 6px;">
            <div>
              <img
                src="../../../assets/images/home/yuanqiwxcode.png"
                style="width: 128px; height: 128px"
              />
            </div>
            <div style="margin-top: 6px">小程序</div>
          </div>
          <div>
            <div>
              <img
                src="../../../assets/images/home/qrcode.png"
                style="width: 128px; height: 128px"
              />
            </div>
            <div style="margin-top: 6px">企业微信</div>
          </div>
        </div>
      </div>
    </li>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="300px"
      append-to-body
      lock-scroll
    >
      <div
        style="
          font-weight: 350;
          font-size: 20.7px;
          line-height: 53.7px;
          text-align: center;
        "
      >
        <div>海量图库，每日上新200张</div>
        <div>手机微信扫码，查看图库</div>
      </div>
      <div style="display: flex; align-items: center; justify-content: center">
        <img
          src="../../../assets/images/publicize/homeWxCode.png"
          style="width: 253px; height: auto"
        />
      </div>
    </el-dialog>
  </ul>
</template>

<script>
import Icon from '../../icon/Icon'
export default {
  name: 'Nav',
  components: { Icon },
  data() {
    return {
      dialogVisible: false,
    }
  },
}
</script>
